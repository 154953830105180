<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            sm="12"
            md="6"
          ><h6 class="mb-1">
            Güncelle
          </h6></b-col>
        </b-row>
        <b-row>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group label="Görünen Ad">
              <b-form-input
                v-model="formData.from"
                placeholder="Görünen Ad"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="9"
          >
            <b-form-group label="E-Posta">
              <b-form-input
                v-model="formData.email"
                placeholder="E-Posta"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group label="Giden Sunucu">
              <b-form-input
                v-model="formData.host"
                placeholder="Giden Sunucu"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group label="SMTP Port">
              <b-form-input
                v-model="formData.port"
                placeholder="SMTP Port"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group label="Kullanıcı Adı">
              <b-form-input
                v-model="formData.user"
                placeholder="Kullanıcı Adı"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group label="Parola">
              <b-form-input
                v-model="formData.pass"
                placeholder="Parola"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="12"
          >
            <b-form-group label="Sertifika">
              <v-select
                v-model="formData.crypto"
                label="title"
                placeholder="Sertifika"
                :options="cryptos"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'SMTPConfig',
  components: {
    BForm,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      detailForm: false,
      submitStatus: false,
      cryptos: [
        'SSL', 'TLS',
      ],
      formData: {
        from: null,
        email: null,
        host: null,
        port: null,
        user: null,
        pass: null,
        crypto: null,
      },
    }
  },
  computed: {
    smtpConfigData() {
      return this.$store.getters['smtpConfig/getSmtpconfig']
    },
    saveStatus() {
      const status = this.$store.getters['smtpConfig/getSmtpconfigSaveStatus']
      return status
    },
  },
  watch: {
    smtpConfigData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getSmtpConfig()
  },
  methods: {
    getSmtpConfig() {
      this.$store.dispatch('smtpConfig/smtpconfigView')
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('smtpConfig/smtpconfigSave', this.formData)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
